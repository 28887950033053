import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../models/url';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'any',
})
export class PuntoRetiroService {
	constructor(public http: HttpClient) { }
	url = Url;
	token = localStorage.getItem('token');
	rol = localStorage.getItem('rol');

	headers = {
		headers: new HttpHeaders({
			token: this.token,
			rol: this.rol,
		}),
	};

	nuevo(nombre, negocio) {
		return this.http
			.post(`${this.url}puntoretiro/nuevo`, { nombre, negocio }, this.headers)
			.pipe(map((data: any) => data));
	}

	getNegocioId(id) {
		return this.http
			.get(`${this.url}puntoretiro/negocio?id=${id}`, this.headers)
			.pipe(map((data: any) => data));
	}

	todos() {
		return this.http
			.get(`${this.url}puntoretiro/todos`, this.headers)
			.pipe(map((data: any) => data));
	}

	actualizar(nombre, id) {
		return this.http
			.put(`${this.url}puntoretiro/actualizar/${id}`, { nombre }, this.headers)
			.pipe(map((data: any) => data));
	}

	eliminar(id) {
		return this.http
			.delete(`${this.url}puntoretiro/eliminar/${id}`, this.headers)
			.pipe(map((data: any) => data));
	}
}
